<template>
  <MainLayout>
    <form action="" @submit.prevent="submit">
      <div class="intro-y mt-5 mr-4">
        <div
          v-if="isLoading"
          class="absolute bg-white w-full h-full z-100 flex justify-center items-center z-50"
        >
          <div class="opacity-100">
            <LoadingSpinner :is-loading="isLoading" :use-state="useState" />
          </div>
        </div>
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 2xl:col-span-12 -mb-10 pb-10">
            <div class="intro-y box pb-6">
              <div
                class="flex items-center p-5 border-b border-gray-200 dark:border-dark-5"
              >
                <h2 class="font-medium text-base mr-auto">
                  Form Ajuan Perubahan Data Kepegawaian
                </h2>
              </div>

              <div class="grid grid-cols-12 gap-x-8 p-6">
                <div class="col-span-12 lg:col-span-12">
                  <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
                  <div v-if="alertFailed">
                    <AlertFailed
                      v-for="(item, index) in messageAlert"
                      :key="index"
                      :message="item"
                      :dismissable="true"
                    />
                  </div>
                  <div v-if="!ajuan_exist">
                    <table class="table table--sm mt-3">
                      <thead>
                        <tr>
                          <th
                            class="bg-primary-1 text-white border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                          ></th>
                          <th
                            class="bg-primary-1 text-white border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                          >
                            Data Kepegawaian Saat Ini
                          </th>
                          <th
                            class="bg-primary-2 text-white border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                          >
                            Ajuan Perubahan
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5"></td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            <div class="p-5">
                              <div
                                class="w-36 lg:h-48 flex-none image-fit relative"
                              >
                                <img
                                  v-if="pegawai?.foto"
                                  alt="profile-pic"
                                  :src="
                                    profile_pic_url.slice(
                                      0,
                                      profile_pic_url.length - 4
                                    ) +
                                    'storage/sdm/data_pribadi' +
                                    '/' +
                                    pegawai?.foto
                                  "
                                />
                              </div>
                            </div>
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div class="p-5 flex items-center">
                              <div
                                class="w-36 lg:h-48 flex-none image-fit relative"
                              >
                                <img
                                  v-if="imagePreviewURL"
                                  :src="imagePreviewURL"
                                  alt="profile-pic"
                                />
                                <img
                                  v-else
                                  src="@/assets/images/profile-1.jpg"
                                />
                                <div
                                  class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-theme-1 rounded-full p-2"
                                >
                                  <CameraIcon
                                    class="w-4 h-4 text-white"
                                  ></CameraIcon>
                                  <input
                                    id="fileUpload1"
                                    type="file"
                                    class="inputfile"
                                    @change="onFileChange($event)"
                                  />
                                </div>
                              </div>
                              <div class="ml-6 mr-auto">
                                <div class="font-medium d-none">
                                  * Upload Foto Close Up
                                </div>
                                <div class="text-gray-600 text-xs mt-0.5">
                                  - Foto formal ukuran 3x4 (354 x 472 pixel)<br />
                                  - Maksimal ukuran file: <b>5 MB</b><br />
                                  - Ekstensi file: <b>.jpg | .png</b>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Nama</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.NM_PEGAWAI }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.NM_PEGAWAI"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.NM_PEGAWAI,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.NM_PEGAWAI,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">
                            Jenis Kelamin
                          </td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ $h.jenisKelamin(pegawai?.JENIS_KEL) }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div class="flex flex-col sm:flex-row">
                              <div
                                class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2"
                              >
                                <input
                                  id="radio-switch-1"
                                  v-model="ajuan_pegawai.JENIS_KEL"
                                  class="form-check-input bg-white"
                                  type="radio"
                                  value="L"
                                />
                                <label
                                  class="form-check-label"
                                  for="radio-switch-1"
                                  >Laki-Laki</label
                                >
                              </div>
                              <div
                                class="form-check form-control border-0 shadow-none w-auto pl-0 mr-2 mt-2 sm:mt-0"
                              >
                                <input
                                  id="radio-switch-2"
                                  v-model="ajuan_pegawai.JENIS_KEL"
                                  class="form-check-input bg-white"
                                  type="radio"
                                  value="P"
                                />
                                <label
                                  class="form-check-label"
                                  for="radio-switch-2"
                                  >Perempuan</label
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">
                            Tanggal Lahir
                          </td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ $h.tanggal(pegawai?.TGL_LAHIR) }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.TGL_LAHIR"
                                type="date"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.TGL_LAHIR,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.TGL_LAHIR,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Agama</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ $h.agama(pegawai?.AGAMA) }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <TailSelect
                                v-model="ajuan_pegawai.AGAMA"
                                :options="{
                                  search: false,
                                  classNames: !pegawai?.AGAMA
                                    ? [
                                        'form-control',
                                        'border-theme-9',
                                        'border-b',
                                        'font-medium',
                                        'dark:border-dark-5',
                                      ]
                                    : [
                                        'form-control',
                                        'border-red-600',
                                        'border-b',
                                        'font-medium',
                                        'dark:border-dark-5',
                                      ],
                                  deselect: false,
                                }"
                              >
                                <option
                                  v-for="list in ref_agama"
                                  :key="list.value"
                                  :value="list.value"
                                  :selected="list.selected"
                                >
                                  {{ list.text }}
                                </option>
                              </TailSelect>
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">
                            Status Perkawinan
                          </td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ $h.statKawin(pegawai?.STATUS_PERKAWINAN) }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <TailSelect
                                v-model="ajuan_pegawai.STATUS_PERKAWINAN"
                                :options="{
                                  search: false,
                                  classNames: !pegawai?.STATUS_PERKAWINAN
                                    ? [
                                        'form-control',
                                        'border-theme-9',
                                        'border-b',
                                        'font-medium',
                                        'dark:border-dark-5',
                                      ]
                                    : [
                                        'form-control',
                                        'border-red-600',
                                        'border-b',
                                        'font-medium',
                                        'dark:border-dark-5',
                                      ],
                                  deselect: false,
                                }"
                              >
                                <option
                                  v-for="list in ref_statkawin"
                                  :key="list.value"
                                  :value="list.value"
                                  :selected="list.selected"
                                >
                                  {{ list.text }}
                                </option>
                              </TailSelect>
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">
                            Golongan Darah
                          </td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.gol_darah }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.gol_darah"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.gol_darah,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.gol_darah,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Alamat</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.ALAMAT1 }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.ALAMAT1"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.ALAMAT1,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.ALAMAT1,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Kelurahan</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.KEL }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.KEL"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.KEL,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.KEL,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Kecamatan</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.KEC }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.KEC"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.KEC,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.KEC,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Kode Pos</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.KDPOS }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.KDPOS"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.KDPOS,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.KDPOS,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">HP</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.HP }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.HP"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.HP,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.HP,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">Email</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.EMAIL }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.EMAIL"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.EMAIL,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.EMAIL,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">KTP</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.ktp }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.ktp"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.ktp,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.ktp,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">NPWP</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.npwp }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.npwp"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.npwp,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.npwp,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">BPJS TK</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.bpjs_tk }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.bpjs_tk"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.bpjs_tk,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.bpjs_tk,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">BPJS KS</td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.bpjs_ks }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.bpjs_ks"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.bpjs_ks,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.bpjs_ks,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5">
                            No. Rekening
                          </td>
                          <td
                            class="text-primary-1 bg-green-100 border-b font-medium dark:border-dark-5"
                          >
                            {{ pegawai?.NOREK }}
                          </td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <input
                                v-model="ajuan_pegawai.NOREK"
                                class="form-control"
                                :class="{
                                  'border-red-600 border-b font-medium dark:border-dark-5':
                                    !pegawai?.NOREK,
                                  'border-theme-9 border-b font-medium dark:border-dark-5':
                                    pegawai?.NOREK,
                                }"
                                required
                              />
                            </div>
                          </td>
                        </tr>
                        <tr class="bg-gray-50 dark:bg-dark-1">
                          <td class="border-b dark:border-dark-5"></td>
                          <td
                            class="bg-green-100 border-b font-medium dark:border-dark-5"
                          ></td>
                          <td class="border-b dark:border-dark-5">
                            <div>
                              <button
                                type="submit"
                                class="btn my-4 py-3 btn-primary w-full text-lg"
                                :disabled="isLoading || disabled"
                              >
                                <SendIcon class="w-6 h-6 mr-2" />
                                {{
                                  isLoading
                                    ? "Loading..."
                                    : "Kirim Ajuan Perubahan Data"
                                }}
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-else>
                    <div class="grid grid-cols-12 gap-x-8">
                      <div
                        class="bg-gray-50 col-span-12 lg:col-span-12 mt-2 mb-6 py-5"
                      >
                        <h2 class="font-medium text-base mx-6 mb-3">
                          Status Ajuan
                        </h2>
                        <div
                          class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                        >
                          <div class="text-gray-500 dark:text-gray-300">
                            Status
                          </div>
                          <div
                            class="mt-2 dark:text-white sm:mt-0 sm:col-span-2"
                          >
                            <span
                              v-if="status_ajuan.status_ajuan == 0"
                              class="font-medium text-yellow-500"
                              >DIAJUKAN</span
                            >
                            <span
                              v-else-if="status_ajuan.status_ajuan == 1"
                              class="font-medium text-green-500"
                              >DISETUJUI</span
                            >
                            <span
                              v-else-if="status_ajuan.status_ajuan == 2"
                              class="font-medium text-red-500"
                              >DITOLAK</span
                            >
                          </div>
                        </div>
                        <div
                          class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                        >
                          <div class="text-gray-500 dark:text-gray-300">
                            Riwayat Ajuan
                          </div>
                          <div
                            class="mt-2 dark:text-white sm:mt-0 sm:col-span-2"
                          >
                            <div class="mb-1">
                              <b>DIAJUKAN</b> pada tanggal
                              <b>{{ $h.tanggal(status_ajuan.tgl_create) }}</b>
                            </div>
                            <div
                              v-if="
                                status_ajuan.status_ajuan > 0 &&
                                status_ajuan.tgl_ajuan
                              "
                              class="mb-1"
                            >
                              Status
                              <b>{{
                                status_ajuan.status_ajuan == 1
                                  ? "DISETUJUI"
                                  : "DITOLAK"
                              }}</b>
                              diperbarui pada tanggal
                              <b>{{ $h.tanggal(status_ajuan.tgl_ajuan) }}</b>
                            </div>
                          </div>
                        </div>
                        <div
                          class="px-2 pb-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                        >
                          <div class="text-gray-500 dark:text-gray-300">
                            Keterangan Periksa
                          </div>
                          <div
                            class="mt-2 dark:text-white sm:mt-0 sm:col-span-2"
                          >
                            {{ status_ajuan.keterangan_ajuan }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <table class="table table--sm mt-3">
                      <thead>
                        <tr>
                          <th
                            class="bg-primary-1 text-white border border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                            width="40%"
                          >
                            Data Kepegawaian Lama
                          </th>
                          <th
                            class="bg-primary-1 text-white border border-b-2 dark:border-dark-5 whitespace-nowrap text-lg"
                            width="40%"
                          >
                            Data Kepegawaian Baru (Sedang proses pengajuan)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="bg-gray-100 border">
                            <table class="w-full">
                              <tr>
                                <td colspan="3">
                                  <div class="p-5">
                                    <div
                                      class="w-36 lg:h-48 flex-none image-fit relative"
                                    >
                                      <img
                                        v-if="pegawai?.foto"
                                        alt="profile-pic"
                                        :src="
                                          profile_pic_url.slice(
                                            0,
                                            profile_pic_url.length - 4
                                          ) +
                                          'storage/sdm/data_pribadi' +
                                          '/' +
                                          pegawai.foto
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Nama</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  {{ pegawai?.NM_PEGAWAI }}
                                </td>
                              </tr>
                              <tr>
                                <td>Jenis Kelamin</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  {{ $h.jenisKelamin(pegawai?.JENIS_KEL) }}
                                </td>
                              </tr>
                              <tr>
                                <td>Tanggal Lahir</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ $h.tanggal(pegawai?.TGL_LAHIR) }}</td>
                              </tr>
                              <tr>
                                <td>Agama</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ $h.agama(pegawai?.AGAMA) }}</td>
                              </tr>
                              <tr>
                                <td>Status Perkawinan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  {{ $h.statKawin(pegawai?.STATUS_PERKAWINAN) }}
                                </td>
                              </tr>
                              <tr>
                                <td>Golongan Darah</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.gol_darah }}</td>
                              </tr>
                              <tr>
                                <td>Alamat</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.ALAMAT1 }}</td>
                              </tr>
                              <tr>
                                <td>Kelurahan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.KEL }}</td>
                              </tr>
                              <tr>
                                <td>Kecamatan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.KEC }}</td>
                              </tr>
                              <tr>
                                <td>Kode Pos</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.KDPOS }}</td>
                              </tr>
                              <tr>
                                <td>HP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.HP }}</td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.EMAIL }}</td>
                              </tr>
                              <tr>
                                <td>KTP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.ktp }}</td>
                              </tr>
                              <tr>
                                <td>NPWP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.npwp }}</td>
                              </tr>
                              <tr>
                                <td>BPJS TK</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.bpjs_tk }}</td>
                              </tr>
                              <tr>
                                <td>BPJS KS</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.bpjs_ks }}</td>
                              </tr>
                              <tr>
                                <td>No. Rekening</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>{{ pegawai?.NOREK }}</td>
                              </tr>
                            </table>
                          </td>
                          <td class="bg-gray-50 border">
                            <table class="w-full">
                              <tr>
                                <td colspan="3">
                                  <div class="p-5">
                                    <div
                                      class="w-36 lg:h-48 flex-none image-fit relative"
                                    >
                                      <img
                                        alt="profile-pic"
                                        :src="
                                          profile_pic_url.slice(
                                            0,
                                            profile_pic_url.length - 4
                                          ) +
                                          'storage/sdm/data_pribadi' +
                                          '/' +
                                          ajuan_pegawai.profile_pic
                                        "
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>Nama</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.NM_PEGAWAI"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai.NM_PEGAWAI }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Jenis Kelamin</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.JENIS_KEL"
                                    class="font-bold text-red-500"
                                    >{{
                                      $h.jenisKelamin(ajuan_pegawai?.JENIS_KEL)
                                    }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Tanggal Lahir</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.TGL_LAHIR"
                                    class="font-bold text-red-500"
                                    >{{
                                      $h.tanggal(ajuan_pegawai?.TGL_LAHIR)
                                    }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Agama</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.AGAMA"
                                    class="font-bold text-red-500"
                                    >{{ $h.agama(ajuan_pegawai?.AGAMA) }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Status Perkawinan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.STATUS_PERKAWINAN"
                                    class="font-bold text-red-500"
                                    >{{
                                      $h.statKawin(
                                        ajuan_pegawai?.STATUS_PERKAWINAN
                                      )
                                    }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Golongan Darah</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.gol_darah"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.gol_darah }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Alamat</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.ALAMAT1"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.ALAMAT1 }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Kelurahan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.KEL"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.KEL }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Kecamatan</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.KEC"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.KEC }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Kode Pos</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.KDPOS"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.KDPOS }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>HP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.HP"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.HP }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>Email</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.EMAIL"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.EMAIL }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>KTP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.ktp"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.ktp }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>NPWP</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.npwp"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.npwp }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>BPJS TK</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.bpjs_tk"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.bpjs_tk }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>BPJS KS</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.bpjs_ks"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.bpjs_ks }}</span
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td>No. Rekening</td>
                                <td
                                  style="padding-left: 0px; padding-right: 0px"
                                >
                                  :
                                </td>
                                <td>
                                  <span
                                    v-if="ajuan_pegawai.NOREK"
                                    class="font-bold text-red-500"
                                    >{{ ajuan_pegawai?.NOREK }}</span
                                  >
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </MainLayout>
</template>
<script>
import MainLayout from "@/layouts/main/MainLayout";
import AlertSuccess from "@/components/alerts/AlertSuccess";
import AlertFailed from "@/components/alerts/AlertFailed";
import LoadingSpinner from "@/components/loading-spinner/Main";
import { ref, sdm } from "@/services/models";

export default {
  components: {
    AlertSuccess,
    AlertFailed,
    MainLayout,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      useState: false,
      disabled: true,
      messageAlert: [],
      userData: this.$store.state.userdata?.userData,
      imagePreviewURL: null,
      profile_pic: null,
      profile_pic_url: process.env.VUE_APP_API,
      pegawai: null,
      ajuan_pegawai: {},
      ajuan_exist: true,
      status_ajuan: [],
      ref_agama: [],
      ref_statkawin: [
        {
          id: 0,
          value: "B",
          text: "Belum Kawin",
          selected: false,
        },
        {
          id: 1,
          value: "N",
          text: "Kawin",
          selected: false,
        },
      ],
    };
  },
  watch: {
    ajuan_pegawai: {
      deep: true,
      handler() {
        let data = this.ajuan_pegawai;

        Object.keys(data)
          .filter((key) => data[key] === undefined || data[key] === "")
          .map((key) => delete data[key]);

        if (Object.keys(data).length > 0) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
  },
  mounted() {
    this.getAgama();
    this.getDetailPegawai();
  },
  methods: {
    async getAgama() {
      ref.agama.getAll().then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((element) => {
            this.ref_agama.push({
              id: element.id,
              value: element.id,
              text: element.name,
              selected: false,
            });
          });
        }
      });
    },
    async getDetailPegawai() {
      this.isLoading = true;
      sdm.SDM.getCustomPath(
        `ajuan_perubahan_data_pribadi/${this.userData?.uid}`
      )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data) {
              this.ajuan_pegawai = res.data.data;
              this.status_ajuan = res.data.status;
            } else {
              this.ajuan_exist = res.data.data;
            }

            sdm.SDM.getCustomPath(`data_pribadi/${this.userData?.uid}`).then(
              (res) => {
                this.pegawai = res.data.data;
                if (!this.ajuan_exist) {
                  this.ajuan_pegawai.NM_PEGAWAI = this.pegawai.NM_PEGAWAI
                    ? this.pegawai.NM_PEGAWAI
                    : "";
                  this.ajuan_pegawai.JENIS_KEL = this.pegawai.JENIS_KEL
                    ? this.pegawai.JENIS_KEL
                    : "";
                  this.ajuan_pegawai.TGL_LAHIR = this.pegawai.TGL_LAHIR
                    ? this.pegawai.TGL_LAHIR
                    : "";
                  this.ajuan_pegawai.ALAMAT1 = this.pegawai.ALAMAT1
                    ? this.pegawai.ALAMAT1
                    : "";
                  this.ajuan_pegawai.KEL = this.pegawai.KEL
                    ? this.pegawai.KEL
                    : "";
                  this.ajuan_pegawai.KEC = this.pegawai.KEC
                    ? this.pegawai.KEC
                    : "";
                  this.ref_agama.forEach((element) => {
                    if (element.value == this.pegawai.AGAMA) {
                      this.ajuan_pegawai.AGAMA = this.pegawai.AGAMA;
                      element.selected = true;
                    }
                  });
                  this.ref_statkawin.forEach((element) => {
                    if (element.value == this.pegawai.STATUS_PERKAWINAN) {
                      this.ajuan_pegawai.STATUS_PERKAWINAN =
                        this.pegawai.STATUS_PERKAWINAN;
                      element.selected = true;
                    }
                  });
                  this.ajuan_pegawai.gol_darah = this.pegawai.gol_darah
                    ? this.pegawai.gol_darah
                    : "";
                  this.ajuan_pegawai.KDPOS = this.pegawai.KDPOS
                    ? this.pegawai.KDPOS
                    : "";
                  this.ajuan_pegawai.HP = this.pegawai.HP
                    ? this.pegawai.HP
                    : "";
                  this.ajuan_pegawai.EMAIL = this.pegawai.EMAIL
                    ? this.pegawai.EMAIL
                    : "";
                  this.ajuan_pegawai.ktp = this.pegawai.ktp
                    ? this.pegawai.ktp
                    : "";
                  this.ajuan_pegawai.npwp = this.pegawai.npwp
                    ? this.pegawai.npwp
                    : "";
                  this.ajuan_pegawai.bpjs_tk = this.pegawai.bpjs_tk
                    ? this.pegawai.bpjs_tk
                    : "";
                  this.ajuan_pegawai.bpjs_ks = this.pegawai.bpjs_ks
                    ? this.pegawai.bpjs_ks
                    : "";
                  this.ajuan_pegawai.NOREK = this.pegawai.NOREK
                    ? this.pegawai.NOREK
                    : "";
                }
              }
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    onFileChange(event) {
      //const file = payload.target.files[0]; // use it in case of normal HTML input
      const file = event.target.files[0]; // in case vuetify file input
      if (file) {
        this.imagePreviewURL = URL.createObjectURL(file);
        URL.revokeObjectURL(file); // free memory
        this.profile_pic = file;
      } else {
        this.imagePreviewURL = null;
      }
    },
    submit() {
      this.alertSuccess = false;
      this.alertFailed = false;
      this.messageAlert = [];
      this.isLoading = true;

      let formdata = new FormData();
      for (const key in this.ajuan_pegawai) {
        formdata.append(key, this.ajuan_pegawai[key]);
      }
      //
      // const fd = new FormData();
      formdata.append("profile_pic", this.profile_pic);
      // formdata = {
      //   ...this.ajuan_pegawai,
      // };

      sdm.SDM.postCustomPath(
        `ajuan_perubahan_data_pribadi/${this.userData?.uid}`,
        formdata
      ).then((res) => {
        if (res.status === 200) {
          if (res.data.code === 200) {
            this.alertSuccess = true;
            this.messageAlert = res.data.message;

            this.ajuan_exist = true;
            this.getDetailPegawai();
          } else {
            this.alertFailed = true;
            this.messageAlert.push(res.data.message);
          }
        }

        this.isLoading = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped>
.inputfile {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  cursor: pointer;
  padding: 10px;
  opacity: 0;
}

.inputfile + .fileinfo {
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  height: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inputfile:focus + .fileinfo,
.inputfile + .fileinfo:hover,
.inputfile:hover + .fileinfo {
  cursor: pointer;
}

.inputfile + .fileinfo {
  cursor: pointer;
}
</style>
