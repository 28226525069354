<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <BreadCrumb />
    <AccountMenu />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BreadCrumb from "./BreadCrumb";
import AccountMenu from "./AccountMenu";

export default defineComponent({
  components: {
    AccountMenu,
    BreadCrumb,
  },
});
</script>
